@import "~bootswatch/dist/united/variables";
@import "~bootswatch/dist/united/bootswatch";

$success: #12c9a4;
//$primary: #14939c; // Türkis
//$primary: #d43383; // Pink

@import "~bootstrap/scss/bootstrap";

// jQuery UI
@import "~jquery-ui/themes/base/all.css";

// Font Awesome
//@import "~font-awesome/css/font-awesome.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

// Datatables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";

// Selectize
@import "~selectize/dist/css/selectize.css";
@import "~selectize/dist/css/selectize.default.css";

// ChartJS
@import "~chart.js/dist/Chart.css";

// Vis
@import "~vis/dist/vis.css";

// jQuery Confirm
@import '~jquery-confirm/dist/jquery-confirm.min.css';

// Noty
@import '~noty/lib/noty.css';
@import '~noty/lib/themes/metroui.css';

// Flatpickr
@import '~flatpickr/dist/flatpickr.css';
@import '~flatpickr/dist/themes/material_red.css';

@import '~daterangepicker/daterangepicker.css';

// Custom
@import "custom.scss";
