* {

}

select {
-webkit-appearance: none;
-moz-appearance: none;
}

/* Navigation */

#navigation {
    position: fixed;
    top:0;
    left:0;
    z-index: 1001;
    width: 100%;
    height: 50px !important; /*`70*/
}

#navigation nav {
    box-shadow: 0 0 20px rgba(0,0,0,0.4); z-index: 999 !important;
    background: rgba(44,62,80,1);
    background: -moz-linear-gradient(left, rgba(44,62,80,1) 0%, rgba(44,62,80,1) 58%, rgba(28,91,102,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(44,62,80,1)), color-stop(58%, rgba(44,62,80,1)), color-stop(100%, rgba(28,91,102,1)));
    background: -webkit-linear-gradient(left, rgba(44,62,80,1) 0%, rgba(44,62,80,1) 58%, rgba(28,91,102,1) 100%);
    background: -o-linear-gradient(left, rgba(44,62,80,1) 0%, rgba(44,62,80,1) 58%, rgba(28,91,102,1) 100%);
    background: -ms-linear-gradient(left, rgba(44,62,80,1) 0%, rgba(44,62,80,1) 58%, rgba(28,91,102,1) 100%);
    background: linear-gradient(to right, rgba(44,62,80,1) 0%, rgba(44,62,80,1) 58%, rgba(28,91,102,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c3e50', endColorstr='#1c5b66', GradientType=1 );
    color: #fff !important;
    border-bottom: solid 2px turquoise !important;
}

#navigation li.nav-item {
    border-left: solid 1px #415160 !important;
}

#navigation li a {
    padding: 14px 20px 13px 20px;
}

#navigation li:first-child a {
    color:#fff;
    font-style: italic;
}
#navigation li:first-child a:hover {
    text-decoration:none;
}

#navigation .logo {
    width: 204px;
    text-shadow: 0 0 10px turquoise;
}

.sidebar-mini-mode #navigation .logo {
    width: 100px;
}

#navigation .logo a {
    padding: 14px 20px 13px 0;
}

#top_navigation_icons_left li a:hover i {
    color: #fff !important;
    text-shadow: 0 0 10px turquoise;
}

#top_navigation_icons_left li a.active i {
    color: #fff !important;
    text-shadow: 0 0 10px turquoise;
}

/* Content */

body.sidebar-right-show #content {
    margin-right:300px;
}

#content {
    margin-left:225px;
    margin-top: 55px;
    padding:10px 15px 10px 10px;
}

#content .container-fluid {
    padding:20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 3px;
}

/* Inputs */

.input-geldbetrag {
    text-align: right;
}

/* Footer */

.footer {
    height: 50px;
    width: 100%;
    left: 215px;
    padding-right: 300px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0;
    padding-left: 32px;
    z-index: 888;
    padding-top: 15px;
    display:block;
}
.footer:hover {
    opacity: 0;
}

.darkmode .footer {
    opacity: 0;
    background: #2c3e50 !important;
}
.darkmode .footer:hover {
    opacity: 0;
}

/* Fonts */

h1 {
    font-size: 1.5em;
}

h2 {
    font-size: 1.3em !important;
    font-weight: bold !important;
    margin-bottom: 25px !important;
}

.text-black {
    color:#000 !important;
}

.lead-xl {
    font-size: 3em;
}

*:focus {
    outline: none;
}

.text-white-on-blue {
    color:#becfe0;
}

.border-white-on-blue {
    border:solid 1px #becfe0 !important;
}

/* Sidebar */

#sidebar {
    left:0;
    top: 51px;
    height:100%;
    position:fixed;
    width: 220px; /* Nur Icons: 55px */
    overflow: hidden;
    background: #efefef !important;
    z-index: 999;
}

.darkmode #sidebar {
    background: #495b6c !important;
}

#sidebar a {
    display: block;
    width:100% !important;
    padding: 15px 20px !important;
    border-bottom: solid 1px #eee !important;
    white-space: nowrap;
    color: #333;
    background: #fafafa;
}

.darkmode #sidebar a {
    display: block;
    width:100% !important;
    padding: 15px 20px !important;
    border-bottom: solid 1px #415160 !important;
    white-space: nowrap;
    color: #becfe0 !important;
    background: #495b6c !important;
}
.darkmode #sidebar a:not(:first-child) {
    border-top: solid 1px #506274 !important;
}

#sidebar a.active {
    background: #eee;
    color: orangered;
}
.darkmode #sidebar a.active {
    background: #415160;
    color: white;
}

#sidebar a.active i {
    color: orangered !important;
    text-shadow: 0 0 10px orangered;
}

.darkmode #sidebar a.active i {
    color: white !important;
    text-shadow: 0 0 10px turquoise;
}

#sidebar a:hover {
    text-decoration: none;
    background: #fff;
}

.darkmode #sidebar a:hover:not(.menu-heading) {
    background: #415160 !important;
}

.darkmode #sidebar .menu-heading {
    background: #253e50 !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}

.sidebar-mini-mode #content {
    margin-left: 60px;
}

.sidebar-mini-mode .sidebar-text,
.sidebar-mini-mode .menu-heading{
    display:none !important;
}

.sidebar-mini-mode #sidebar {
    width: 55px;
}

/* Sidebar Rechts */

body.sidebar-right-show #sidebar_right {
    display:block;
}

#sidebar_right {
    display: none;
    right: 0;
    top: 50px;
    height:100%;
    position:fixed;
    width: 300px;
    overflow: hidden;
    background: #efefef !important;
}

.darkmode #sidebar_right {
    background: #495b6c !important;
}

.darkmode #sidebar_right a {
    border-bottom: solid 1px #415160 !important;
    color: #becfe0 !important;
    background: #495b6c !important;
}
.darkmode #sidebar_right a:not(:first-child) {
    border-top: solid 1px #506274 !important;
}

.darkmode #sidebar_right a.active {
    background: #415160;
    color: white;
}

.darkmode #sidebar_right a.active i {
    color: white !important;
    text-shadow: 0 0 10px turquoise;
}

.darkmode #sidebar_right a:hover {
    background: #415160;
}

.darkmode #sidebar_right .menu-heading {
    padding: 15px 20px !important;
    background: #253e50 !important;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    display:block;
}

.darkmode .menu-heading:hover {
    background: #253e50 !important;
    text-decoration: none !important;
}


/* Bootstrap Extensions */

/*
.form-control,
.custom-select {
    border:none !important;
    border-bottom: solid 1px #0fa687 !important;
    border-radius: 0;
    background:#fafafa;
}
*/

.list-group-item {
    cursor:pointer;
}

.list-group-item:hover {
    background: $light;
}

.border-radius-0 {
    border-radius: 0 !important;
}

.border-radius-left-0 {
    border-radius: 0 5px 5px 0 !important;
}

.border-radius-right-0 {
    border-radius: 5px 0 0 5px !important;
}

.bg-superlight {
    background:#fcfcfc;
}

.card-body table.table tr th {
    border-top:none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-move {
    cursor: move;
}

.inline-checkboxes label {
    margin-right: 20px;
}

.inline-checkboxes input[type='checkbox'] {
    margin-right: 5px;
}

.popover{
    max-width: 1200px !important;
}

/* Textbausteine */

#ausgewaehlte_textbausteine {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

#ausgewaehlte_textbausteine li {
    border-bottom: dotted 1px #eee;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

#ausgewaehlte_textbausteine li:hover {
    text-decoration: underline;
}

.textbaustein:hover {
    font-weight: bold;
    cursor: pointer;
}

.glow-primary {
    box-shadow: 0 0 15px orangered !important;
}

/* jQueryUI */

.ui-autocomplete {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Notizen */

.notiz {
    border-bottom:solid 1px #eee;
}

/* Loading Animation */
.loading {
    width: 100%;
    height: 100%;
    right:0;
    top:0;
    position:fixed;
    background: #fafafa;
    z-index: 998;
    padding-top: 65px;
    padding-right: 25px;
    display: none;
}

.jumbotron-dashboard {
    background: #78ffd6;
    background: -webkit-linear-gradient(to right, #007991, #78ffd6);
    background: linear-gradient(to right, #007991, #78ffd6);
}

/* Benachrichtigungen */

#benachrichtigungen a {
    color: orangered !important;
    padding:0 !important;
}

/* Datatables */

#lead_datatable_wrapper #lead_datatable_filter,
#textbausteine_datatable_length, #textbausteine_datatable_filter
{
    display: none !important;
}
#lead_datatable_processing {
    display: none !important;
}

.sorting, .sorting_asc, .sorting_desc {
    background : none;
}

#textbausteine_datatable thead tr th {
    border-top: none !important;
}

/* Tiny MCE */

.tox-statusbar__branding {
    display: none;
}

.tox.tox-tinymce {

}

.tox-menubar,
.tox-toolbar {
    background-color: #efefef !important; /* uses !important or override .mce-panel background-color/image */
    background-image: none !important;
}


/* Textbausteine */

.textbaustein_preview {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding:10px 20px 10px 10px;
    width: 100%;
    height:400px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

/* Textbausteine Browser */

.textbausteine-browser>ul{

}

.textbausteine-browser ul {
    margin:0 0 0 10px;
    padding:0;
}

.textbausteine-browser ul li {
    list-style: none;
}

.textbausteine-browser li[data-type='folder'] {
    cursor: pointer;
}

.textbausteine-browser li[data-type='folder'] span:hover{
    text-decoration: underline;
}


.textbausteine-browser li[data-type='file'] {
    color: #000;
    cursor: pointer;
}

.textbausteine-browser li[data-type='file'] span:hover {
    text-decoration: underline;
}

#ausgewaehlte_textbausteine li:hover{
    text-decoration: none !important;
    cursor: pointer;
}

/* File Browser */

.file-browser li {
    position: relative;
    margin-left: 10px;
    &:before{
        position: absolute;
        left: -15px;
        top: 0;
        content: '';
        display: block;
        border-left: 1px solid #ddd;
        height: 1em;
        border-bottom: 1px solid #ddd;
        width: 10px;
    }
    &:after {
        position: absolute;
        left: -15px;
        bottom: -7px;
        content: '';
        display: block;
        border-left: 1px solid #ddd;
        height:100%;
    }
    &.root-folder{
        margin: 0 0 0 -10px;
        &:before{
            display: none;
        }
        &:after {
            display: none;
        }
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
}

/* Lead Show */

/*
#lead_stammdaten_anschrift .input-group-text {
    background: #fafafa;
    color: #aaa !important;
} */

#lead_index .dataTables_length {
    display: none;
}

#lead_index .nav-tabs a {
    color: $text-muted;
}
#lead_index .nav-tabs a .badge{
    opacity: 0.7;
}

#lead_index .nav-tabs a.active {
    color: $orange;
}
#lead_index .nav-tabs a.active .badge {
    opacity: 1.0;
}

.custom-combobox input {
    padding-right: 40px;
}

.custom-combobox a {
    width: 40px;
    position: absolute;
    right: 0;
    margin-top: -31px;
    margin-right: 15px;
    height: calc(1.5em + 0.5rem + -1px);
}



